<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { Component } from 'vue'
import { computed, inject, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import FormFieldFactory from '@/components/FormFieldFactory.vue'
import ConfettisHeart from '@/components/icons/ConfettisHeart.vue'
import IconBuilding from '@/components/icons/IconBuilding.vue'
import IconCheque from '@/components/icons/IconCheque.vue'
import { captureSentryException } from '@/sentry'
import { useContactStore, usePaymentStore, useStore, useWidgetsStore } from '@/store'
import type {
  CheckoutPaymentMethods,
  CheckoutResponseData,
  CustomPaymentMethod,
  CustomPaymentMethodName,
  FieldConfig,
  NorbrTokenType,
  NorbrWidgetBuildData,
  OrderResponseData,
  PaymentTerminology,
  Section,
} from '@/types'
import { getFormattedAmount } from '@/utils/currency'
import { attachFieldsExtraConfig } from '@/utils/form-fields'
import type { Norbr } from '@/utils/norbr'
import { buildWidget, dispatchToggleConfirmButtonEvent } from '@/utils/norbr'
import { omit, sortedByWeight } from '@/utils/objects'
import {
  API_URL_MAPPING,
  NorbrError,
  RGIVE_PARTNER,
  RGiveBadRequestError,
  callCheckout,
} from '@/utils/payment'

const emit = defineEmits<{
  (e: 'openTaxReductionModal'): void
  (e: 'selectCustomPaymentMethod'): void
}>()

// Config injected from backend
const norbrApiPublicKey = inject('norbrApiPublicKey') as string
const norbrApiEnvironment = inject('norbrApiEnvironment') as 'sandbox' | 'production'
const paymentTerminology = inject<PaymentTerminology>('paymentTerminology') as PaymentTerminology
const campaignId = inject('uuid') as string
const language = inject('language') as string
const frontBaseUrl = inject<string>('frontBaseUrl') as string
const apiBaseUrl = inject('apiBaseUrl') as string
const isOverlay = inject('isOverlay') as boolean
const currency = inject('currency') as string
const formFieldsDisplay = inject('formFieldsDisplay') as Record<Section, FieldConfig[]>

const { t } = useI18n()
const { isWidgetEnabled } = useWidgetsStore()
const { getStepIndex, campaignTaxReductionType, goToStep, setInvalidFields, resetInvalidFields } =
  useStore()
const {
  norbrCheckoutId,
  norbrToken,
  reference: paymentOrCommitmentUuid,
  paymentMethods,
} = storeToRefs(usePaymentStore())
const {
  currentType,
  baseAmount,
  totalAmount,
  equivalenceIsSelected,
  taxReductionApplicableAmount,
  amountAfterTaxReduction,
  desiredStep,
  initialStep,
  paymentData,
  addonData,
  landingUrl,
  parentUrl,
  invalidFields,
} = storeToRefs(useStore())
const { contact } = storeToRefs(useContactStore())
const { paymentMethod, popupOpened } = storeToRefs(usePaymentStore())

const norbrLoadingContainerHeightInPx = ref('0px')
const norbrPaymentContainer = ref<HTMLDivElement | null>(null)
const customForm = ref<HTMLFormElement | null>(null)
const isNorbrFormSubmitted = ref(false)
const isNorbrInitiated = ref(false)
const isLoading = ref(false)

let norbr: typeof Norbr
const error = ref<string | null>(null)
const popup = ref<WindowProxy | null>(null)
let form: HTMLFormElement | null = null
const customPaymentMethodIcons: Record<CustomPaymentMethodName, unknown | Component> = {
  iban: IconBuilding,
  cheque: IconCheque,
}

// Returns confirm button CSS classes
const confirmButtonCssIcon = ref('')
const confirmButtonClasses = computed<string>(() =>
  ['btn', 'btn-pay', 'w-100', paymentMethod.value].join(' ')
)

// When confirmButtonClasses changes, re-apply CSS classes to NORBr button element
watch(confirmButtonClasses, () => {
  const $norbrBtnElement: HTMLElement | null = document.querySelector(
    '#norbr-payment-button-element'
  )
  if (!$norbrBtnElement) {
    return
  }

  // Update button label for Paypal
  $norbrBtnElement.classList.value = confirmButtonClasses.value
  if (paymentMethod.value === 'paypal') {
    $norbrBtnElement.innerText = t('payment_step.pay_with')
  } else {
    $norbrBtnElement.innerText = t('payment_step.pay')
  }

  // Update icon CSS variable
  const icon = norbr.paymentMethods.payment_methods_available.find(
    (el: any) => el.name === norbr.paymentMethodName
  )?.logo_colorbg as string
  confirmButtonCssIcon.value = icon ? `https://assets-sandbox.norbr.io/image/logo/${icon}` : ''
})

// Returns filtered custom form fields for this step
const customFormFields = computed(() =>
  sortedByWeight(formFieldsDisplay['step-3'] || [], currentType.value)
)

// Returns filtered available custom payment methods
const customPaymentMethodsAvailable = computed<CustomPaymentMethod[]>(
  () =>
    paymentMethods.value?.filter(
      ({ partner }: { partner: string }) => partner === RGIVE_PARTNER
    ) as CustomPaymentMethod[]
)

// Returns filtered NORBr payment methods
const norbrPaymentMethods = computed<CheckoutPaymentMethods>(
  () =>
    paymentMethods.value?.filter(({ partner }: { partner: string }) => partner !== RGIVE_PARTNER) ||
    []
)

// Validity of NORBr form
const isNorbrFormValid = ref(false)

// Returns validity of custom form
const isCustomFormValid = computed<boolean>(() => {
  return (
    !customFormFields.value.length ||
    (customFormFields.value.every((config: FieldConfig) => {
      return (
        config.display !== 'required' ||
        !!(config.attached_to === 'contact' ? contact : paymentData).value[config.name]
      )
    }) &&
      !!customForm.value &&
      customForm.value.checkValidity())
  )
})

// Dispatch event on change of custom form validity
watch(isCustomFormValid, (valid: boolean) => {
  if (isNorbrInitiated.value) {
    dispatchToggleConfirmButtonEvent(valid)
  }
})

function onCustomPaymentMethodSelect(selectedPaymentMethod: CustomPaymentMethodName) {
  if (!isCustomFormValid.value) {
    return
  }

  paymentMethod.value = selectedPaymentMethod
  emit('selectCustomPaymentMethod')
}

function onNorbrFormSubmit() {
  if (!isCustomFormValid.value) {
    return
  }

  error.value = null
  isNorbrFormSubmitted.value = true
  openPaymentPopup()
  window.norbr_submit()
}

function onNorbrFormKeyPress(e: Event) {
  if (!(e instanceof KeyboardEvent)) {
    return
  }

  // Submit form on "Enter" key press...
  if (e.key === 'Enter' || e.code === 'Enter') {
    onNorbrFormSubmit()
  }
}

function onNorbrFormChange() {
  isNorbrFormValid.value = norbr.formCorrect
  paymentMethod.value = norbr.paymentMethodName
  dispatchToggleConfirmButtonEvent(isCustomFormValid.value)
}

function addListeners() {
  form = document.querySelector('#norbr-payment-form')

  // Listen to keypress on NORBr form
  form?.addEventListener('keypress', onNorbrFormKeyPress)

  // Listen to changes on NORBr form
  form?.addEventListener('change', onNorbrFormChange)
}

function removeListeners() {
  form?.removeEventListener('keypress', onNorbrFormKeyPress)
  form?.querySelectorAll('input[type="radio"]').forEach(($inputRadio) => {
    $inputRadio.removeEventListener('change', onNorbrFormChange)
  })
}

watch(isLoading, () => {
  // Update height of loading container to same height than #norbr-payment-container
  if (isLoading.value) {
    const height = norbrPaymentContainer.value?.clientHeight || 250
    norbrLoadingContainerHeightInPx.value = `${height}px`
  } else {
    norbrLoadingContainerHeightInPx.value = '0px'
  }
})

watch(popup, () => {
  popupOpened.value = popup.value !== null
})

async function initPaymentForms() {
  paymentMethod.value = null

  // Call checkout and initialize NORBr widget
  const isMobile = window.matchMedia('(max-width: 768px)').matches
  try {
    isLoading.value = true
    error.value = null
    resetInvalidFields('step-1')
    resetInvalidFields('step-2')

    const checkoutResponseData: CheckoutResponseData = await callCheckout(
      {
        payment: (currentType.value === 'one-off' && paymentOrCommitmentUuid.value) || undefined,
        commitment: (currentType.value === 'regular' && paymentOrCommitmentUuid.value) || undefined,
        campaign: campaignId,
        base_amount: baseAmount.value,
        amount: totalAmount.value,
        equivalence_selected: equivalenceIsSelected.value,
        currency: currency,
        type: currentType.value,
        device_type: isMobile ? 'mobile' : 'desktop',
        contact: contact.value,
        addon_data: addonData.value,
        landing_url: landingUrl.value,
        parent_url: parentUrl.value,
        language,
        is_overlay: isOverlay,
        ...paymentData.value,
      },
      `${apiBaseUrl}${API_URL_MAPPING.checkout[currentType.value]}`
    )

    norbrCheckoutId.value = checkoutResponseData.checkout.checkout_id
    paymentOrCommitmentUuid.value =
      currentType.value === 'one-off'
        ? checkoutResponseData.payment_uuid
        : checkoutResponseData.commitment_uuid
    paymentMethods.value = checkoutResponseData.payment_methods

    initializeNorbr({
      tokenType: checkoutResponseData.checkout.token_type as NorbrTokenType,
      checkoutId: norbrCheckoutId.value,
      subscriptionId: checkoutResponseData.checkout?.subscription_id,
    })
  } catch (e) {
    if (e instanceof RGiveBadRequestError) {
      // Some fields were invalid on backend-side, go back to the earliest step they're displayed on
      setInvalidFields(e.fields)

      if (invalidFields.value['step-1'].length > 0) {
        goToStep('amount')
      } else {
        goToStep('contact')
      }
    } else if (e instanceof NorbrError) {
      // Something failed with norbr init
      error.value = e.message || t('form_loading_failed')
    } else {
      // Something failed
      error.value = t('form_loading_failed')
      if (typeof window.norbr_submit === 'undefined') {
        // Norbr is not loaded, may be using script blocker
        error.value += ` ${t('norbr_blocked')}`
      } else {
        // Anything other reason, could be a rate limiting
        error.value += ` ${t('too_many_requests')}`
      }
    }
    captureSentryException(e)
  } finally {
    isLoading.value = false
  }
}

function initializeNorbr(data: {
  checkoutId: string
  tokenType: NorbrTokenType
  subscriptionId?: string
}) {
  isLoading.value = true
  isNorbrFormSubmitted.value = false

  const widgetBuildData: NorbrWidgetBuildData = {
    publicApiKey: norbrApiPublicKey,
    environment: norbrApiEnvironment,
    tokenType: data.tokenType,
    paymentMethods: norbrPaymentMethods.value,
    checkoutId: data.checkoutId,
    subscriptionId: data.subscriptionId,
    reference: paymentOrCommitmentUuid.value as string,
    campaignUuid: campaignId,
    contact: contact.value,
    paymentData: paymentData.value,
    language,
    ariaLabels: {
      cardNumber: t('card_number'),
      expirationDate: t('exp_date'),
      cvc: t('cvc'),
    },
  }

  if (norbrPaymentMethods.value.length) {
    initializeNorbrWidget(widgetBuildData)
  }

  isNorbrInitiated.value = true
}

function initializeNorbrWidget(widgetBuildData: NorbrWidgetBuildData) {
  function overwriteSubmitFn() {
    // Update onSubmit norbr method to be able to
    // set loading / error after submission
    const initialNorbrOnSubmit = norbr.configuration.onSubmit
    norbr.configuration.onSubmit = async () => {
      try {
        error.value = null
        isLoading.value = true
        resetInvalidFields('step-3')

        const orderResponseData: OrderResponseData = await initialNorbrOnSubmit()
        if (orderResponseData.url) {
          if (isOverlay) {
            // In case of overlay, redirection is done inside the popup when possible
            isLoading.value = false

            if (popup.value === null) {
              // Popup could not open
              if (new URL(orderResponseData.url).origin === new URL(frontBaseUrl).origin) {
                // When redirect URL is on front domain, redirect in iframe
                window.location.href = orderResponseData.url
              } else {
                // Otherwise, redirect in iframe's parent
                window.open(orderResponseData.url, '_parent')
              }
            } else {
              popup.value.document.location.href = orderResponseData.url
              popup.value.resizeTo(990, 900)

              window.addEventListener(
                'message',
                (event: MessageEvent) => {
                  if (event.origin !== window.location.origin || !event.data.rgivePopupUrl) return
                  popup.value?.close()
                  popup.value = null
                  window.focus()
                  window.location.href = event.data.rgivePopupUrl
                },
                false
              )
              const intervalId = setInterval(() => {
                if (popup.value?.closed) {
                  clearInterval(intervalId)
                  popup.value = null
                  initPaymentForms()
                }
              }, 500)
              popup.value.focus()
            }
          } else {
            // In case of not overlay, redirection is done in current window
            window.location.href = orderResponseData.url
          }
        } else if (orderResponseData.error) {
          throw new Error(orderResponseData.error)
        } else {
          isLoading.value = false
        }
      } catch (e: any) {
        popup.value?.close()
        popup.value = null

        // Something went wrong on NORBr side, re-init form to have a new checkout
        initPaymentForms()
        captureSentryException(e)

        if (e instanceof RGiveBadRequestError) {
          // Some fields were invalid on backend-side, go back to the earliest step they're displayed on
          setInvalidFields(e.fields)
          captureSentryException(e)
        } else if (e instanceof NorbrError) {
          error.value = e.message || t('error_message')
        } else {
          // Something failed, could be a rate limiting
          error.value = `${t('error_message')} ${t('too_many_requests')}`
        }
      } finally {
        isNorbrFormSubmitted.value = false
      }
    }
  }

  norbr = buildWidget(widgetBuildData, currentType.value, apiBaseUrl)
  paymentMethod.value = 'cards'
  norbrToken.value = norbr.token

  overwriteSubmitFn()
  addListeners()

  // Simulate change on NORBr form to set selected paymentMethod
  onNorbrFormChange()
}

onMounted(() => {
  // Reset desired step when at tunnel's end
  if (desiredStep.value && getStepIndex(desiredStep.value) >= getStepIndex('payment')) {
    initialStep.value = desiredStep.value
    desiredStep.value = undefined
  }

  initPaymentForms()
})

onBeforeUnmount(() => {
  removeListeners()
})

const thanks_text_keypath = computed(() => {
  // "payment" terminology is forced when total amount is not fully applicable to tax reduction
  const paymentTerminologyToUse: PaymentTerminology =
    totalAmount.value === taxReductionApplicableAmount.value ? paymentTerminology : 'payment'

  /* After-tax amount is displayed only for donations
   *  Tax condition is not displayed to companies and wealth tax campaigns */
  if (paymentTerminologyToUse === 'donation' && isWidgetEnabled('taxReduction')) {
    if (campaignTaxReductionType === 'income') {
      return 'payment_step.thanks_message.with_tax_reduction_and_condition'
    } else {
      return 'payment_step.thanks_message.with_tax_reduction'
    }
  } else {
    return `payment_step.thanks_message.amount_only.${paymentTerminologyToUse}`
  }
})

async function openPaymentPopup() {
  // Popup needs to be opened as soon as the user clicks on the button so that it's not blocked by the browser
  // Popup is not opened when form is not valid (we assume that NORBr form is always valid for non-cards payment methods)
  // Popup is not opened for GooglePay & ApplePay because the browser will open one for card selection
  await nextTick() // Wait for isNorbrFormValid to update
  if (
    isOverlay &&
    isCustomFormValid.value &&
    (paymentMethod.value !== 'cards' || isNorbrFormValid.value) &&
    !['google_pay', 'apple_pay'].includes(paymentMethod.value || '')
  ) {
    popup.value = window.open('', 'paymentPopup', 'popup,top=0,left=0,width=1,height=1,rel=opener')
  }
}
</script>

<template>
  <div class="py-3 overlay-scroll d-flex justify-content-between flex-column h-100">
    <div class="scroll-shadows">
      <div
        class="position-relative overflow-hidden mx-4 mb-3 p-2 ps-5 py-3 bg-secondary-transparent rounded-2 text-secondary fs-7 lh-sm"
      >
        <ConfettisHeart class="position-absolute top-0 start-0 no-color" :aria-hidden="true" />

        <i18n-t :keypath="thanks_text_keypath">
          <template #thank_you_name>
            <strong>
              <i18n-t keypath="payment_step.thank_you_name">
                <template #name_or_empty>
                  {{ contact?.firstname ? `&nbsp;${contact?.firstname}` : '' }}
                </template>
              </i18n-t>
            </strong>
          </template>
          <template #amount>{{ getFormattedAmount(totalAmount, language, currency) }}</template>
          <template #oneoff_or_empty>
            {{ currentType === 'one-off' ? t(`payment_step.oneoff`) : '' }}
          </template>
          <template #per_month_or_empty>
            {{ currentType === 'regular' ? t(`payment_step.per_month`) : '' }}
          </template>
          <template #amount_after_reduction
            ><strong>{{ getFormattedAmount(amountAfterTaxReduction, language, currency) }}</strong>
          </template>
          <template #tax_link>
            <button class="btn btn-link btn-inline" @click.prevent="emit('openTaxReductionModal')">
              {{ t('tax_reduction.link') }}
            </button>
          </template>
        </i18n-t>
      </div>

      <div v-if="popup" class="mx-4 mb-2 bg-primary-subtle border-0 rounded-2 p-2">
        {{ t('popup.text_waiting') }}
        <button class="btn btn-link" @click="popup.focus()">{{ t('popup.btn_focus') }}</button>
      </div>
      <div v-if="error" class="mx-4 mb-2 norbr-alert border-0 rounded-2 p-2">
        {{ error }}
      </div>

      <div class="pb-2 px-4">
        <div
          v-show="isLoading"
          id="norbr-loading-container"
          :style="{ '--norbr-loading-container-height-in-px': norbrLoadingContainerHeightInPx }"
        >
          <span class="spinner-grow spinner-grow-sm me-2" aria-hidden="true" />
        </div>

        <div
          id="norbr-payment-container"
          ref="norbrPaymentContainer"
          :class="{ 'with-custom-payment-methods': customPaymentMethodsAvailable?.length }"
        />

        <div v-show="isNorbrInitiated && !isLoading && !popup">
          <div
            id="custom-payment-methods-container"
            :class="{ 'with-norbr-payment-methods': norbrPaymentMethods?.length }"
          >
            <button
              v-for="customPaymentMethod in customPaymentMethodsAvailable"
              :key="customPaymentMethod.name"
              class="payment-method-card d-flex w-100"
              :class="`payment-method-card-${customPaymentMethod.name}`"
              @click="onCustomPaymentMethodSelect(customPaymentMethod.name)"
            >
              <div class="payment-method-card-body">
                <div class="d-flex justify-content-between">
                  <span class="payment-name">
                    {{ t(`payment_methods.${customPaymentMethod.name}`) }}
                  </span>
                  <span class="payment-logo">
                    <component
                      :is="customPaymentMethodIcons[customPaymentMethod.name]"
                      class="no-color"
                    />
                  </span>
                </div>
              </div>
            </button>
          </div>

          <form
            v-if="formFieldsDisplay['step-3']?.length"
            ref="customForm"
            class="d-flex flex-wrap mt-3"
            :class="{ 'was-validated': paymentMethod && !isCustomFormValid }"
            @submit.prevent=""
          >
            <div
              v-if="invalidFields['step-3'].length > 0"
              class="norbr-alert mb-2 border-0 rounded-2 p-2"
            >
              {{ t('invalid_fields', { field_names: invalidFields['step-3'].join(', ') }) }}
            </div>
            <FormFieldFactory
              v-for="config in attachFieldsExtraConfig(customFormFields)"
              v-bind="omit(config, 'weight', 'attached_to')"
              :key="config.name"
              v-model="(config.attached_to === 'contact' ? contact : paymentData)[config.name]"
              :full-model="config.attached_to === 'contact' ? contact : paymentData"
            />
          </form>
        </div>
      </div>
    </div>

    <div
      id="norbr-payment-button"
      :style="{ '--icon': `url(${confirmButtonCssIcon})` }"
      :data-label="t('payment_step.pay')"
      :data-class="confirmButtonClasses"
      data-theme="black"
      class="m-2 mx-4"
      @click="openPaymentPopup"
    />
  </div>
</template>
