<!-- A component handling checkboxes and radios -->
<template>
  <fieldset>
    <legend class="col-form-label">{{ label }}{{ `${required ? ' *' : ''}` }}</legend>
    <div
      v-for="(choice, index) in choices"
      :key="choice[0]"
      class="form-check form-check-inline"
      :class="{
        'was-validated': validated,
      }"
    >
      <input
        :id="`${name}-${index}`"
        :ref="(el) => setFirstInputElementRef(index, el as HTMLInputElement)"
        v-model="value"
        :name="name"
        class="form-check-input"
        :type="type === 'radio' ? 'radio' : 'checkbox'"
        :required="type === 'radio' && required"
        :value="choice[0]"
        :checked="type === 'radio' ? modelValue === choice[0] : modelValue.includes(choice[0])"
        @blur="validated = props.required"
        @focus="validated = false"
      />
      <label class="form-check-label" :for="`${name}-${index}`">{{ choice[1] }}</label>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import { onMounted, ref, toValue, watch } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    required: boolean
    type: 'checkboxes' | 'radio'
    name: string
    choices: [string, string][]
    label?: string
    modelValue?: any
  }>(),
  {
    modelValue: () => [],
    label: '',
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: any): void
}>()

const initialValue = toValue(props.modelValue)
const value = ref(
  props.type === 'radio'
    ? initialValue
    : // Prevent bad cookie value with multiple, when array is expected
      Array.isArray(initialValue)
      ? initialValue
      : [initialValue]
)
const firstElement = ref()

function checkValidity() {
  if (props.type === 'checkboxes' && props.required) {
    if (value.value.length) {
      firstElement.value.setCustomValidity('')
    } else {
      firstElement.value.setCustomValidity(t('missing_value'))
    }
  }
}

watch(value, () => {
  checkValidity()
  emit('update:modelValue', value.value)
})

const validated = ref(false)

onMounted(checkValidity)

function setFirstInputElementRef(index: number, el: HTMLInputElement) {
  if (index === 0) {
    firstElement.value = el
  }
}
</script>
