<template>
  <div id="payment-details" class="py-3 mx-3">
    <template v-if="paymentMethod === 'iban'">
      <div class="text-center">
        <div class="mt-1 text-gray-300">
          <IconBuilding size="70" />
        </div>
      </div>

      <div v-if="error" class="mt-3 mb-2 norbr-alert border-0 rounded-2 p-2">
        {{ error }}
      </div>

      <form @submit.prevent="onIbanSubmit">
        <div class="my-3 fw-bold">{{ t('payment_details_form.iban.iban_label') }}</div>
        <FormInput
          id="iban"
          v-model="iban"
          type="text"
          :label="ibanPlaceholder"
          required
          no-floating-label
          :validators="[validateIBAN]"
          :formatter="IBAN.printFormat"
        />

        <template v-if="selectedPaymentMethod?.additional_information?.requires_bic">
          <div class="my-3 fw-bold">{{ t('payment_details_form.iban.bic_label') }}</div>
          <FormInput
            id="bic"
            v-model="bic"
            type="text"
            :label="bicPlaceholder"
            required
            no-floating-label
            :validators="[validateBIC]"
            :formatter="formatBic"
          />
        </template>

        <div class="my-3 py-2 px-3 bg-secondary-transparent rounded-2 text-secondary lh-sm">
          <FormSwitch
            v-model="authorize"
            name="authorize"
            :label="
              t('payment_details_form.iban.authorize', { organization_name: organizationName })
            "
            required
          />
        </div>

        <button
          v-if="!isLoading"
          type="submit"
          class="btn btn-pay w-100 my-2"
          :disabled="isLoading || !iban || !authorize"
        >
          {{ t('payment_details_form.iban.pay') }}
          <IconBuilding class="ms-1 align-text-top" />
        </button>

        <div v-if="isLoading" class="loading-container text-center">
          <span class="spinner-grow spinner-grow-sm me-2" aria-hidden="true" />
        </div>
      </form>
    </template>

    <template v-if="paymentMethod === 'cheque'">
      <div class="text-center">
        <div class="mt-1 text-gray-300">
          <IconCheque width="70" />
        </div>
      </div>

      <div v-if="error" class="mt-3 mb-2 norbr-alert border-0 rounded-2 p-2">
        {{ error }}
      </div>

      <div class="my-3 fw-bold text-center">
        {{ t(`payment_details_form.cheque.title.${paymentTerminology}`) }}
      </div>

      <div class="cheque-info mb-3">
        <label class="fw-bold fs-7 mb-1">{{
          t('payment_details_form.cheque.cheque_order_label')
        }}</label>
        <div class="break-lines">
          {{ selectedPaymentMethod?.additional_information?.order || '' }}
        </div>
      </div>

      <div class="cheque-info mb-3">
        <label class="fw-bold fs-7 mb-1">{{
          t('payment_details_form.cheque.cheque_address_label')
        }}</label>
        <div class="break-lines">
          {{ selectedPaymentMethod?.additional_information?.address || '' }}
        </div>
      </div>

      <button
        v-if="!isLoading"
        type="submit"
        class="btn btn-pay w-100 mt-4 mb-2"
        :disabled="isLoading"
        @click="onChequeSubmit"
      >
        {{ t('payment_details_form.cheque.confirm') }}
        <IconCheque class="ms-1 align-text-top" width="1.5em" />
      </button>

      <div v-if="isLoading" class="loading-container text-center">
        <span class="spinner-grow spinner-grow-sm me-2" aria-hidden="true" />
      </div>
    </template>

    <div class="text-center">
      <button
        v-if="!isLoading && otherPaymentMethodsAvailable"
        :aria-label="t('payment_details_form.change_payment_method')"
        class="back-link no-style fs-7 text-underline"
        @click="emit('cancel')"
      >
        {{ t('payment_details_form.change_payment_method') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import IBAN from 'iban'
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import IconBuilding from '@/components/icons/IconBuilding.vue'
import IconCheque from '@/components/icons/IconCheque.vue'
import FormInput from '@/components/inputs/FormInput.vue'
import FormSwitch from '@/components/inputs/FormSwitch.vue'
import { captureSentryException } from '@/sentry'
import { useContactStore, usePaymentStore, useStore } from '@/store'
import type { CustomPaymentMethod, OrderResponseData, PaymentTerminology } from '@/types'
import { getBicFormat, getIbanFormat } from '@/utils/iban'
import { API_URL_MAPPING, callConfirmIban, callSendChequePledge } from '@/utils/payment'

const emit = defineEmits<{
  (e: 'cancel'): void
}>()

const { t } = useI18n()

const { contact } = storeToRefs(useContactStore())
const { paymentData, currentType } = storeToRefs(useStore())
const { reference, paymentMethod, paymentMethods } = storeToRefs(usePaymentStore())

// Config injected from backend
const paymentTerminology = inject<PaymentTerminology>('paymentTerminology')
const apiBaseUrl = inject('apiBaseUrl') as string
const organizationCountryCode = inject<string>('organizationCountryCode', '')
const organizationName = inject<string>('organizationName', '')

const isLoading = ref(false)
const error = ref<string | null>(null)
const iban = ref<string>('')
const bic = ref<string>('')
const authorize = ref(false)

const selectedPaymentMethod = computed<CustomPaymentMethod | null>(() =>
  paymentMethods.value?.find((pm) => pm.name === paymentMethod.value)
)

async function processSubmit(responsePromise: Promise<OrderResponseData>) {
  try {
    error.value = null
    isLoading.value = true
    const orderResponseData: OrderResponseData = await responsePromise

    if (orderResponseData.url) {
      window.location.href = orderResponseData.url
    } else if (orderResponseData.error) {
      throw new Error(orderResponseData.error)
    } else {
      isLoading.value = false
    }
  } catch (e: any) {
    captureSentryException(e)
    console.error('Error:', e)
    error.value = t('error_message')
    isLoading.value = false
  }
}

/* IBAN section */
const ibanPlaceholder = computed(() =>
  getIbanFormat(contact.value?.country as string, organizationCountryCode)
)
const bicPlaceholder = computed(() =>
  getBicFormat(contact.value?.country as string, organizationCountryCode)
)
function onIbanSubmit() {
  if (currentType.value !== 'regular') {
    return
  }

  processSubmit(
    callConfirmIban(
      {
        iban: IBAN.electronicFormat(iban.value),
        bic: unformatBic(bic.value),
        commitment: reference.value as string,
        payment_method_name: paymentMethod.value as string,
        contact: contact.value,
        payment_data: paymentData.value,
      },
      `${apiBaseUrl}${API_URL_MAPPING.confirmIban}`
    )
  )
}
function formatBic(bic: string) {
  const unformatted = unformatBic(bic)
  // Two blocks of 4 characters, and one block with the rest
  return [unformatted.substring(0, 4), unformatted.substring(4, 8), unformatted.substring(8)]
    .filter(Boolean)
    .join(' ')
}
function unformatBic(bic: string) {
  return bic.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
}
function validateIBAN(value: any) {
  if (!IBAN.isValid(value)) {
    return t('payment_details_form.iban.invalid_iban')
  }
}
function validateBIC(value: string) {
  if (value && !new RegExp('[A-Z]{6}[A-Z0-9]{2,5}').test(unformatBic(value))) {
    return t('payment_details_form.iban.invalid_bic')
  }
}

/* Cheque section */
async function onChequeSubmit() {
  if (currentType.value !== 'one-off') {
    return
  }

  processSubmit(
    callSendChequePledge(
      {
        payment: reference.value as string,
        payment_method_name: paymentMethod.value as string,
        contact: contact.value,
        payment_data: paymentData.value,
      },
      `${apiBaseUrl}${API_URL_MAPPING.sendPledge}`
    )
  )
}

const otherPaymentMethodsAvailable = computed(
  () => (paymentMethods.value || []).filter((method) => method.name !== paymentMethod.value).length
)

onMounted(() => {
  if (!['iban', 'cheque'].includes(paymentMethod.value as string)) {
    emit('cancel')
  }
})
</script>
