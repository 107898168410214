import { defineStore, storeToRefs } from 'pinia'
import { computed, inject, ref } from 'vue'

import type {
  AddonDataItem,
  AddonField,
  CheckoutPaymentMethods,
  Contact,
  CustomPaymentMethodName,
  EnabledPaymentType,
  EquivalenceGrid,
  FormFieldsDisplay,
  OrganizationType,
  PaymentType,
  Section,
  Step,
  TaxReductionType,
  WidgetName,
  WidgetOptions,
  Widgets,
} from '@/types'
import { getAddonAmount, getAddonChoice, getAddonField } from '@/utils/addons'
import { getContactPrefillData } from '@/utils/prefill'
import { getAmountAfterTaxReduction, getFirstTaxReductionPercent } from '@/utils/tax-reduction'

const STEPS: Step[] = ['amount', 'push-regular1', 'contact', 'payment', 'payment-details']
export const SELECTED_AMOUNTS_ZERO = {
  'one-off': 0,
  regular: 0,
}

export const useConfigStore = defineStore(
  'config',
  () => {
    const campaignUuid = ref(inject<String>('uuid'))

    // Default config that comes from backend
    const defaultActivateFreeAmount = inject('activateFreeAmount') as boolean
    const defaultEnabledPaymentTypes = inject(
      'enabledPaymentTypes',
      'one-off_regular'
    ) as EnabledPaymentType
    const defaultAmountsOneoff = inject('amountsOneoff', []) as number[]
    const defaultAmountsRegular = inject('amountsRegular', []) as number[]
    const defaultPopularOneoff = inject<number>('popularOneoff')
    const defaultPopularRegular = inject<number>('popularRegular')

    // Storage refs
    const activateFreeAmount = ref(defaultActivateFreeAmount)
    const enabledPaymentTypes = ref(defaultEnabledPaymentTypes)
    const amountsOneoff = ref(defaultAmountsOneoff)
    const amountsRegular = ref(defaultAmountsRegular)
    const popularOneoff = ref(defaultPopularOneoff)
    const popularRegular = ref(defaultPopularRegular)

    const equivalenceGridOneoff = inject('equivalenceGridOneoff') as EquivalenceGrid | null
    const equivalenceGridRegular = inject('equivalenceGridRegular') as EquivalenceGrid | null

    const initialSelectedAmounts = computed(() => {
      return {
        'one-off': amountsOneoff.value.length === 1 ? amountsOneoff.value[0] : 0,
        regular: amountsRegular.value.length === 1 ? amountsRegular.value[0] : 0,
      }
    })

    function isGridAmount(amount: number, type: PaymentType): boolean {
      return type === 'one-off'
        ? amountsOneoff.value.includes(amount)
        : amountsRegular.value.includes(amount)
    }

    function $reset() {
      campaignUuid.value = inject('uuid')
      activateFreeAmount.value = defaultActivateFreeAmount
      enabledPaymentTypes.value = defaultEnabledPaymentTypes
      amountsOneoff.value = defaultAmountsOneoff
      amountsRegular.value = defaultAmountsRegular
      popularOneoff.value = defaultPopularOneoff
      popularRegular.value = defaultPopularRegular
    }

    function isDefaultValues() {
      return (
        activateFreeAmount.value === defaultActivateFreeAmount &&
        enabledPaymentTypes.value === defaultEnabledPaymentTypes &&
        popularOneoff.value === defaultPopularOneoff &&
        popularRegular.value === defaultPopularRegular &&
        // The following value are arrays, so compare their representation
        JSON.stringify(amountsOneoff.value) === JSON.stringify(defaultAmountsOneoff) &&
        JSON.stringify(amountsRegular.value) === JSON.stringify(defaultAmountsRegular)
      )
    }

    return {
      campaignUuid,
      activateFreeAmount,
      enabledPaymentTypes,
      amountsOneoff,
      popularOneoff,
      amountsRegular,
      popularRegular,
      equivalenceGridOneoff,
      equivalenceGridRegular,
      initialSelectedAmounts,
      isGridAmount,
      isDefaultValues,
      $reset,
    }
  },
  {
    // Prevent persisting config in backoffice
    persist: !window.isBackoffice && {
      debug: import.meta.env.DEV,
      afterRestore: (ctx) => {
        // We check if the last store used was related to this campaign and if changed, we start fresh
        if (ctx.store.campaignUuid !== inject('uuid') && !ctx.store.isDefaultValues()) {
          ctx.store.$reset()
          if (import.meta.env.DEV) {
            console.info("Resetting 'config' because not the same campaign")
          }
        }
      },
    },
  }
)

export const useStore = defineStore(
  'store',
  () => {
    const landingUrl = ref(window.location.href.substring(0, 1024))
    const parentUrl = ref('')

    const campaignUuid = ref(inject<String>('uuid'))

    // Fields that didn't pass backend validation
    const invalidFields = ref<Record<Section, string[]>>({
      'step-1': [],
      'step-2': [],
      'step-3': [],
    })

    // Default config that comes from backend
    const defaultPaymentType = inject('defaultPaymentType', 'one-off')
    const defaultStep = inject<Step>('currentStep', 'amount')
    const addonFields = inject('addonFields', []) as AddonField[]

    // Selected type and step data
    const currentType = ref<PaymentType>(defaultPaymentType)
    const currentStep = ref<Step>(defaultStep)
    const desiredStep = ref<Step>()
    const initialStep = ref<Step>()

    const { initialSelectedAmounts } = useConfigStore()

    // Payment data
    const customAmount = ref<number | '' | null>(null)
    const chosenAmount = ref<Record<PaymentType, number | undefined>>({ ...initialSelectedAmounts })
    const originalOneOffAmount = ref<number>()
    const paymentData = ref<Record<string, any>>({})

    // Addon data
    const addons = ref<Record<string, boolean | string | number>>({})

    // Computed type data
    const isOneOffSelected = computed(() => currentType.value === 'one-off')
    const isRegularSelected = computed(() => currentType.value === 'regular')

    // Computed step data
    const currentStepIndex = computed(() => getStepIndex(currentStep.value))

    // Computed addon data
    const addonData = computed(() => {
      if (currentType.value !== 'one-off') {
        return {}
      }

      const data: Record<string, AddonDataItem> = {}
      Object.entries(addons.value).forEach(([addonName, value]) => {
        const addon = getAddonField(addonFields, addonName)
        let formattedValue: number | boolean | string = value

        if (
          !addon ||
          (addon.type === 'boolean' && !value) ||
          (addon.type === 'number' && !value) ||
          (addon.type === 'dropdown' && !getAddonChoice(addon, value))
        ) {
          return
        }

        if (addon.type === 'boolean') {
          formattedValue = !!value
        } else if (addon.type === 'number') {
          formattedValue = parseFloat(value as string)
        }

        data[addonName] = {
          value: formattedValue,
          amount: getAddonAmount(addonFields, addonName, value),
        }
      })
      return data
    })
    const addonCount = computed(() => Object.values(addonData.value).length)

    // Computed amount data
    const addonsAmount = computed(() => {
      let amount = 0

      // Addons are only applicable for "one-off" type
      if (currentType.value !== 'one-off') {
        return amount
      }

      Object.entries(addons.value).forEach(([addonName, value]) => {
        amount += getAddonAmount(addonFields, addonName, value)
      })
      return amount
    })
    const baseAmount = computed(
      () => customAmount.value || chosenAmount.value[currentType.value] || 0
    )
    const totalAmount = computed(() => baseAmount.value + addonsAmount.value)
    const taxReductionApplicableAddonAmount = computed(() => {
      let amount = 0

      // Addons are only applicable for "one-off" type
      if (currentType.value !== 'one-off') {
        return amount
      }

      Object.entries(addons.value).forEach(([addon, value]) => {
        amount += getAddonAmount(
          addonFields.filter((addon) => addon.tax_reduction_enabled),
          addon,
          value
        )
      })
      return amount
    })
    const taxReductionApplicableAmount = computed(
      () => baseAmount.value + taxReductionApplicableAddonAmount.value
    )

    // Computed data using other stores
    const equivalenceIsSelected = computed<boolean>(() => {
      const { equivalenceGridOneoff, equivalenceGridRegular } = useConfigStore()
      const equivalenceGrids = { 'one-off': equivalenceGridOneoff, regular: equivalenceGridRegular }
      return (
        equivalenceGrids[currentType.value] !== undefined &&
        baseAmount.value === chosenAmount.value[currentType.value]
      )
    })
    const campaignTaxReductionType = computed<TaxReductionType>(() => {
      const { contact } = storeToRefs(useContactStore())
      if (contact.value.is_company) {
        return 'company'
      }
      const { widgetHasOption } = useWidgetsStore()
      return widgetHasOption('taxReduction', 'is_wealth_campaign') ? 'wealth' : 'income'
    })
    const amountAfterTaxReduction = computed(() =>
      getAmountAfterTaxReduction(
        totalAmount.value,
        taxReductionApplicableAmount.value,
        campaignTaxReductionType.value,
        inject<OrganizationType>('organizationType', 'generic'),
        inject<string>('organizationCountryCode', ''),
        currentType.value === 'regular'
      )
    )
    const firstTaxReductionPercent = computed(() =>
      getFirstTaxReductionPercent(
        campaignTaxReductionType.value,
        inject<OrganizationType>('organizationType', 'generic'),
        inject<string>('organizationCountryCode', '')
      )
    )

    function resetAmountSelection() {
      if (customAmount.value === '') {
        chosenAmount.value = { ...initialSelectedAmounts }
      } else {
        chosenAmount.value = {
          'one-off': 0,
          regular: 0,
        }
      }
    }

    function getStepIndex(step: Step) {
      return STEPS.indexOf(step)
    }

    function goToStep(step: Step) {
      currentStep.value = step
    }

    function goToPreviousStep() {
      if (currentStep.value === 'contact') {
        // Skip push-regular1 step when going back
        goToStep('amount')
      } else {
        goToStep(STEPS[Math.max(0, currentStepIndex.value - 1)])
      }
    }

    function resetInvalidFields(step: Section) {
      invalidFields.value[step] = []
    }

    const formFieldsDisplay = inject('formFieldsDisplay') as FormFieldsDisplay

    function setInvalidFields(invalidFieldNames: string[]) {
      /* Sort invalid fields from backend into the step they're displayed on.
       * Fields returned as invalid that are not in the configuration are sorted into step 1, if any */
      Object.entries(formFieldsDisplay).forEach(([step, fieldConfigs]) => {
        invalidFields.value[step as Section] = []

        fieldConfigs.forEach((fieldConfig) => {
          const index = invalidFieldNames.indexOf(fieldConfig.name)
          if (index >= 0) {
            invalidFields.value[step as Section].push(fieldConfig.label)
            invalidFieldNames.splice(index, 1)
          }
        })
      })

      if (invalidFieldNames.length > 0) {
        invalidFields.value['step-1'].push(...invalidFieldNames)
      }
    }

    function $reset() {
      campaignUuid.value = inject('uuid')
      currentType.value = defaultPaymentType
      currentStep.value = 'amount'
      desiredStep.value = undefined
      initialStep.value = undefined
      customAmount.value = null
      chosenAmount.value = { ...initialSelectedAmounts }
      originalOneOffAmount.value = undefined
      paymentData.value = {}
      addons.value = {}
      invalidFields.value = { 'step-1': [], 'step-2': [], 'step-3': [] }
    }

    return {
      campaignUuid,
      currentStep,
      currentStepIndex,
      currentType,
      isOneOffSelected,
      isRegularSelected,
      addons,
      addonsAmount,
      customAmount,
      chosenAmount,
      originalOneOffAmount,
      baseAmount,
      taxReductionApplicableAmount,
      totalAmount,
      equivalenceIsSelected,
      firstTaxReductionPercent,
      amountAfterTaxReduction,
      campaignTaxReductionType,
      desiredStep,
      initialStep,
      paymentData,
      addonData,
      addonCount,
      landingUrl,
      parentUrl,
      invalidFields,
      setInvalidFields,
      resetInvalidFields,
      resetAmountSelection,
      getStepIndex,
      goToStep,
      goToPreviousStep,
      $reset,
    }
  },
  {
    persist: {
      debug: import.meta.env.DEV,
      afterRestore: (ctx) => {
        // We check if the last store used was related to this campaign
        if (ctx.store.campaignUuid !== inject('uuid')) {
          // and if not, we start fresh (except for landingUrl)
          const landingUrl = ctx.store.landingUrl
          ctx.store.$reset()
          ctx.store.landingUrl = landingUrl
          if (import.meta.env.DEV) {
            console.info("Resetting 'store' because not the same campaign")
          }
        }

        // Move currentStep to desiredStep
        if (ctx.store.currentStepIndex > 0) {
          ctx.store.desiredStep = ctx.store.currentStep
          ctx.store.currentStep = 'amount'
        }
      },
    },
  }
)

export const useContactStore = defineStore(
  'contact',
  () => {
    const contact = ref<Contact>({})

    function $reset() {
      contact.value = {}
    }

    async function prefill(fromUrl: Contact | null, contactKey: string | null) {
      /* Update contact with their db-stored data using the contactKey if given, then with data extracted from URL */

      const campaignId = inject<string>('uuid')
      const apiBaseUrl = inject<string>('apiBaseUrl') as string

      const externalContact =
        contactKey && campaignId
          ? await getContactPrefillData(apiBaseUrl, contactKey, campaignId)
          : {}
      const contactFromUrl = fromUrl || {}

      contact.value = {
        ...contact.value,
        ...externalContact,
        ...contactFromUrl,
      }
    }

    return {
      contact,
      prefill,
      $reset,
    }
  },
  {
    persist: {
      debug: import.meta.env.DEV,
      paths: ['contact', 'updatedAt'],
    },
  }
)

export const usePaymentStore = defineStore(
  'basket',
  () => {
    const norbrCheckoutId = ref<string | null>()
    const norbrToken = ref<string | null>()
    const reference = ref<string | null>() // Can reference either payment or commitment
    const paymentMethod = ref<CustomPaymentMethodName | string | null>()
    const paymentMethods = ref<CheckoutPaymentMethods | null>()
    const displayErrorModal = ref(false)
    const popupOpened = ref(false)

    function $reset() {
      norbrCheckoutId.value = null
      norbrToken.value = null
      reference.value = null
      paymentMethods.value = null
      displayErrorModal.value = false
      popupOpened.value = false
    }

    return {
      norbrCheckoutId,
      norbrToken,
      reference,
      paymentMethod,
      paymentMethods,
      displayErrorModal,
      popupOpened,
      $reset,
    }
  },
  {
    persist: {
      debug: import.meta.env.DEV,
      paths: ['reference', 'updatedAt'],
    },
  }
)

export const useWidgetsStore = defineStore('widgets', () => {
  const defaultWidgets = inject('widgets', {})

  const widgets = ref<Widgets>(defaultWidgets)
  const disabledWidgets = ref<WidgetName[]>([])

  const isWidgetEnabled = (widgetName: WidgetName) =>
    !disabledWidgets.value.includes(widgetName) &&
    widgets.value[widgetName] !== null &&
    widgets.value[widgetName] !== undefined

  const getWidgetOptions = (widgetName: WidgetName): WidgetOptions | undefined => {
    if (disabledWidgets.value.includes(widgetName)) {
      return undefined
    }

    return widgets.value[widgetName]
  }

  const getWidgetOption = (widgetName: WidgetName, optionName: string) => {
    if (disabledWidgets.value.includes(widgetName) || !widgetHasOption(widgetName, optionName)) {
      return undefined
    }

    const widgetOptions = getWidgetOptions(widgetName)
    if (Array.isArray(widgetOptions)) {
      return true
    }

    return widgetOptions?.[optionName]
  }

  const widgetHasOption = (widgetName: WidgetName, optionName: string) => {
    if (disabledWidgets.value.includes(widgetName) || !isWidgetEnabled(widgetName)) {
      return false
    }

    const widgetOptions = getWidgetOptions(widgetName)
    // Some widgets' options are a list of values
    if (Array.isArray(widgetOptions)) {
      return widgetOptions.includes(optionName)
    }
    // Some widgets's options are a dict
    if (widgetOptions instanceof Object) {
      return widgetOptions[optionName] !== undefined
    }
    return false
  }

  function $reset() {
    widgets.value = defaultWidgets
    disabledWidgets.value = []
  }

  return {
    widgets,
    isWidgetEnabled,
    disabledWidgets,
    getWidgetOptions,
    getWidgetOption,
    widgetHasOption,
    $reset,
  }
})
