import type { OrganizationType, TaxReductionType } from '@/types'

declare type ReductionThreshold = {
  reductionPercent: number
  threshold?: number
  maxReduction?: number
  minAmount?: number
}

/**
 * Returns a list of thresholds for tax reduction calculation.
 *
 * A threshold is composed by:
 * - a reductionPercent: the reduction percent to apply
 * - a threshold: the amount until which the reduction can be applied (optional)
 * - a maxReduction: the max reduction that can be applied (optional)
 * - a minAmount: the minimum for the reduction to apply (optional)
 */
function getTaxReductionThresholds(
  taxReductionType: TaxReductionType,
  organizationType: OrganizationType,
  countryCode?: string
): ReductionThreshold[] {
  if (countryCode === 'BE') {
    if (taxReductionType === 'company') {
      return [{ reductionPercent: 40, maxReduction: 500_000 }]
    }

    return [{ reductionPercent: 45, minAmount: 40 }]
  }

  // If no country code passed, apply French regulations

  // For companies, see https://www.economie.gouv.fr/cedef/reduction-impot-dons-associations
  if (taxReductionType === 'company') {
    if (organizationType === 'assistance') {
      return [{ reductionPercent: 60 }]
    }

    return [{ reductionPercent: 60, threshold: 2_000_000 }, { reductionPercent: 40 }]
  }

  // For IR, see https://www.economie.gouv.fr/particuliers/prelevement-a-la-source-reductions-fiscales-dons-associations
  if (taxReductionType === 'income') {
    if (organizationType === 'generic') {
      return [{ reductionPercent: 66 }]
    }

    if (organizationType === 'assistance') {
      return [{ reductionPercent: 75, threshold: 1_000 }, { reductionPercent: 66 }]
    }

    // Case 'cultural' organizations
    return [{ reductionPercent: 75, threshold: 562 }, { reductionPercent: 66 }]
  }

  // For IFI, see https://www.culture.gouv.fr/Thematiques/Mecenat/Particuliers/Des-mesures-pour-favoriser-les-dons-des-particuliers
  return [{ reductionPercent: 75, maxReduction: 50_000 }]
}

export function getFirstTaxReductionPercent(
  taxReductionType: TaxReductionType,
  organizationType: OrganizationType,
  countryCode?: string
): number {
  const thresholds = getTaxReductionThresholds(taxReductionType, organizationType, countryCode)
  return thresholds[0].reductionPercent
}

export function getFirstTaxReductionMinAmount(
  taxReductionType: TaxReductionType,
  organizationType: OrganizationType,
  countryCode?: string
): number {
  const thresholds = getTaxReductionThresholds(taxReductionType, organizationType, countryCode)
  return thresholds[0].minAmount || 0
}

/**
 * Returns the tax reduction applicable for a specific configuration.
 *
 * For instance, if reduction thresholds are as follows:
 * [
 *   { reductionPercent: 75, threshold: 1_000 },
 *   { reductionPercent: 6 }
 * ]
 *
 * The reduction amount for 10_000€ will be calculated as follows:
 *  (1_000€ * 0.75) + ((10_000€ - 1_000€) * 0.66) => 6690 €
 */
export function getTaxReductionAmount(
  amount: number,
  taxReductionType: TaxReductionType,
  organizationType: OrganizationType,
  countryCode?: string,
  isRegularDonation?: boolean
): number {
  const thresholds = getTaxReductionThresholds(taxReductionType, organizationType, countryCode)
  const totalAmount = isRegularDonation ? 12 * amount : amount

  let reduction = 0
  let currentThreshold = 0
  thresholds.forEach(({ reductionPercent, threshold, maxReduction, minAmount }) => {
    // The applicable amount is the amount that is applicable to the current threshold
    const applicableAmount = Math.min(threshold || Infinity, totalAmount) - currentThreshold

    // Do not apply reduction if applicable amount is lower than minimum amount
    if (applicableAmount >= (minAmount || 0)) {
      currentThreshold = applicableAmount
      reduction += (applicableAmount * reductionPercent) / 100

      // Do not apply a reduction higher than max reduction
      reduction = Math.min(reduction, maxReduction || Infinity)
    }
  })

  return isRegularDonation ? reduction / 12 : reduction
}

export function getAmountAfterTaxReduction(
  totalAmount: number,
  taxReductionApplicableAmount: number,
  taxReductionType: TaxReductionType,
  organizationType: OrganizationType,
  countryCode?: string,
  isRegularDonation?: boolean
): number {
  return (
    totalAmount -
    getTaxReductionAmount(
      taxReductionApplicableAmount,
      taxReductionType,
      organizationType,
      countryCode,
      isRegularDonation
    )
  )
}
