import type { App } from 'vue'

import { captureSentryException } from '@/sentry'
import type { EmailVerificationReport } from '@/types'

export abstract class EmailVerifier {
  constructor(
    protected url: string,
    protected timeout: number
  ) {}

  protected cachedResults = new Map<string, EmailVerificationReport>()

  abstract fetchValidation(email: string): Promise<EmailVerificationReport>

  async verifyEmail(email: string): Promise<EmailVerificationReport> {
    if (this.cachedResults.has(email)) {
      return this.cachedResults.get(email)!
    }

    const report = await this.fetchValidation(email)
    this.cachedResults.set(email, report)
    return report
  }
}

export class BouncerEmailVerifier extends EmailVerifier {
  async fetchValidation(email: string): Promise<EmailVerificationReport> {
    const options = { method: 'GET' }

    const query = new URLSearchParams({ email: email, timeout: this.timeout.toString() })

    try {
      const rawResponse = await fetch(this.url + '?' + query.toString(), options)
      const response = await rawResponse.json()
      return {
        valid: response.status !== 'undeliverable' && response.account?.disabled !== 'yes',
        suggestion: response.didYouMean,
      }
    } catch (error) {
      captureSentryException(error)
      return {
        valid: true,
      }
    }
  }
}

const adapters = { BouncerEmailVerifier }

export const emailVerificationPlugin = {
  install: async (app: App, config: { adapter: string; url: string; timeout: number } | null) => {
    if (config && config.adapter in adapters) {
      const Adapter = adapters[config.adapter as keyof typeof adapters]
      app.provide('emailVerifier', new Adapter(config.url, config.timeout))
    } else {
      app.provide('emailVerifier', null)
    }
  },
}
