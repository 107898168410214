<script setup lang="ts">
import type { Component } from 'vue'
import { computed, onMounted } from 'vue'

import FormChecks from '@/components/inputs/FormChecks.vue'
import FormInput from '@/components/inputs/FormInput.vue'
import FormSelect from '@/components/inputs/FormSelect.vue'
import FormSwitch from '@/components/inputs/FormSwitch.vue'
import type { FieldConfig, FieldType } from '@/types'

const props = defineProps<
  Omit<
    FieldConfig & {
      // it seems there's a bug for default_value prop type
      default_value?: string | string[]
      modelValue?: any
      fullModel?: object
    },
    // we exclude attached_to and weight that should have been handled by parent element
    'attached_to' | 'weight'
  >
>()

// Filter out useless properties for rendering
const config = computed(() => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const {
    help_text,
    condition,
    fullModel,
    attached_to,
    is_tracking_field,
    display,
    modelValue,
    default_value,
    ...config
  } = props
  /* eslint-enable @typescript-eslint/no-unused-vars */
  return config
})
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string): void
}>()
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  },
})
onMounted(() => {
  if (typeof props.modelValue === 'undefined' && props.default_value) {
    value.value = props.default_value
  }
})

const specialTypes = {
  boolean: FormSwitch,
  dropdown: FormSelect,
  multiselect: FormSelect,
  radio: FormChecks,
  checkboxes: FormChecks,
} as Record<FieldType, unknown | Component>
</script>

<template>
  <template v-if="Array.isArray(value) && display === 'hidden'">
    <input v-for="val in value" :key="val" type="hidden" :name="name" :value="val" />
  </template>
  <input v-else-if="display === 'hidden'" type="hidden" :value="value" :name="name" />
  <template v-else-if="!props?.condition || !!fullModel[condition]">
    <component
      :is="specialTypes[type as FieldType] || FormInput"
      v-bind="{ ...config, ...$attrs }"
      v-model="value"
      :aria-describedby="help_text ? `help_${name}` : null"
      :required="display === 'required'"
    />
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="help_text"
      :id="`help_${name}`"
      class="w-100 form-text text-muted form-help-text mt--2 mb-3"
      v-html="help_text"
    />
    <!-- eslint-enable -->
  </template>
</template>
